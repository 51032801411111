import request from '@/utils/requestV2'
const qs = require('qs')

// 获取资产下的全部梯牌资源出口去重
export function getAllStairExit (data) {
  return request({
    url: '/ooh-product/v1/asset/getallstairexit',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取媒介主可用的城市
export function getCitys (data) {
  return request({
    url: '/ooh-product/v1/asset/getcitys',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取资产下可用的资源类型
export function getResourcetypeByAsset (data) {
  return request({
    url: '/ooh-product/v1/asset/getresourcetypebyasset',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 统计资产下的站点级别个数（一并返回站点主题色）
export function getAssetStationStatistics (data) {
  return request({
    url: '/ooh-product/v1/asset/getassetstationstatistics',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取资产下的设备类型。目前分为2级
 * @param {Object} data
 * @returns
 */
export function getResourceTypeList (data) {
  return request.post('/ooh-product/v1/asset/getresourcetypelist', qs.stringify(data))
}

/**
 * 获取租户的交易资产
 * @param {Object} data
 * @returns
 */
export function getPublisherProductAssetList (data) {
  return request.post('/ooh-product/v1/asset/getpublisher-assetlist', qs.stringify(data))
}

/**
 * 获取可用的资源类型。目前分为2级
 * @param {Object} data
 * @returns
 */
export function getresourcetypelistbybigtype (data) {
  return request({
    url: '/ooh-product/v1/asset/getresourcetypelistbybigtype',
    method: 'post',
    data: qs.stringify(data)
  })
}
